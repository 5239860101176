import React from "react";
import { Navbar, Button } from "@material-tailwind/react";
import logo from "../assets/logos/logo_transparent.png";
import { Link } from "react-scroll";

export function NavbarFilled() {
  return (
    <Navbar
      fullWidth
      className="py-0 px-4 md:px-8 bg-beige border-none shadow-none"
    >
      <div className="container mx-auto flex items-center justify-between max-w-full">
        <a href="https://adoreagain.shop">
          <div className="flex gap-2 items-center">
            <img src={logo} alt="logo" className="h-24 lg:h-28 w-auto" />
          </div>
        </a>
        <div className="flex gap-8">
          <Link to={"signup"} smooth={true} duration={800}>
            <Button className="shadow-none hover:shadow-none bg-beige bg-opacity-20 text-black">
              Sign Up Now!
            </Button>
          </Link>
        </div>
      </div>
    </Navbar>
  );
}

export default NavbarFilled;
