import React, { useState } from "react";
import { db } from "../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Input,
  Typography,
} from "@material-tailwind/react";
import { FaCircleInfo } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";

export function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccess(false);
    setShowAlert("");

    if (!firstName || !lastName || !email) {
      setSuccess(false);
      setShowAlert("All fields are required.");
      return;
    }

    try {
      await addDoc(collection(db, "mailingList"), {
        first_name: firstName,
        last_name: lastName,
        email: email,
      });
      setSuccess(true);
      setShowAlert("You have been added to the mailing list!");
      setFirstName("");
      setLastName("");
      setEmail("");
    } catch (error) {
      console.error("Error adding document: ", error);
      setSuccess(false);
      setShowAlert(
        "Failed to add to mailing list. Email us directly if the issue persists."
      );
    }
  };

  return (
    <div
      id="signup"
      className="px-4 md:px-8 py-8 lg:py-16 min-h-screen bg-black bg-opacity-50"
    >
      <div className="container mx-auto mb-16 text-center lg:mb-20">
        <Typography
          variant="h1"
          className="mb-4 !text-3xl lg:!text-4xl text-beige"
        >
          Join the Revolution
        </Typography>
        <Typography variant="lead" className="mx-auto text-white">
          Any questions or remarks? Contact Us!
        </Typography>
      </div>
      <div>
        <Card className="max-w-[85rem] mx-auto rounded-2xl backdrop-blur-sm bg-white/80">
          <CardBody className="grid grid-cols-1 gap-x-20 gap-y-8 lg:grid-cols-2 items-center">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-12 lg:max-w-lg mx-auto w-full"
            >
              <div className="grid grid-cols-2 gap-6">
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  color="black"
                  size="lg"
                  variant="standard"
                  label="First Name"
                  name="first-name"
                  placeholder="eg. John"
                  containerProps={{
                    className: "!min-w-full",
                  }}
                  labelProps={{
                    className: "!text-black",
                  }}
                  className="!text-black !border-black"
                />
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  color="black"
                  size="lg"
                  variant="standard"
                  label="Last Name"
                  name="last-name"
                  placeholder="eg. Doe"
                  containerProps={{
                    className: "!min-w-full",
                  }}
                  labelProps={{
                    className: "!text-black",
                  }}
                  className="!text-black !border-black"
                />
              </div>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                color="black"
                size="lg"
                variant="standard"
                label="Email"
                name="email"
                placeholder="eg. john@mail.com"
                containerProps={{
                  className: "!min-w-full",
                }}
                labelProps={{
                  className: "!text-black",
                }}
                className="!text-black !border-black"
              />
              <div className="flex !justify-center lg:mt-24">
                <Button
                  type="submit"
                  className="flex space-x-2 justify-center w-full max-w-md"
                  color="gray"
                  size="md"
                >
                  <IoIosSend className="h-5 w-5" />
                  <Typography className="text-center text-sm font-semibold normal-case">
                    Send
                  </Typography>
                </Button>
              </div>
              {showAlert && (
                <Alert
                  icon={<FaCircleInfo />}
                  color={success ? "light-green" : "red"}
                  show={showAlert}
                  dismissible={true}
                  onClose={() => setShowAlert("")}
                  className="items-center max-w-md mx-auto"
                >
                  {showAlert}
                </Alert>
              )}
            </form>
            <div className="rounded-2xl bg-gray-900 lg:p-20 p-10 w-full">
              <Typography
                variant="h4"
                color="white"
                className="mb-4 text-xl lg:text-3xl"
              >
                Contact Information
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="lg:mb-12 mb-8 text-base opacity-50 max-w-sm"
              >
                We will inform you when the site goes live!
              </Typography>
              <a
                aria-label="phone"
                href="tel:+97470434138"
                className="flex items-center gap-5"
              >
                <IoCall className="h-5 w-5 text-white" />
                <Typography color="white" className="text-sm md:text-lg">
                  +974 7043 4138
                </Typography>
              </a>
              <a
                aria-label="instagram"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/_adoreagain_?igsh=Y3BsYmhldGVseXZp&utm_source=qr"
                className="my-4 flex items-center gap-5"
              >
                <AiFillInstagram className="h-5 w-5 text-white" />
                <Typography color="white" className="text-sm md:text-lg">
                  Instagram
                </Typography>
              </a>
              <a
                aria-label="linkedin"
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/adore-again/"
                className="my-4 flex items-center gap-5"
              >
                <FaLinkedin className="h-5 w-5 text-white" />
                <Typography color="white" className="text-sm md:text-lg">
                  LinkedIn
                </Typography>
              </a>
              <a
                aria-label="email"
                href="mailto:adoreagain23@gmail.com"
                className="my-4 flex items-center gap-5"
              >
                <MdEmail className="h-5 w-5 text-white" />
                <Typography color="white" className="text-sm md:text-lg">
                  adoreagain23@gmail.com
                </Typography>
              </a>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default SignUp;
