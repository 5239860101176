import React from "react";
import { Typography } from "@material-tailwind/react";
import NavbarFilled from "../components/Navbar";
import { Link } from "react-scroll";
import { FaAnglesDown } from "react-icons/fa6";

const Home = () => {
  return (
    <div id="hero" className="flex flex-col w-full min-h-screen">
      <NavbarFilled />
      <div className="relative flex flex-col justify-center flex-1 px-8 bg-black bg-opacity-50">
        <div className="-mt-10 mx-auto w-full max-w-4xl text-beige text-center z-10">
          <Typography className="text-xl lg:text-3xl font-light moveInDown">
            Coming Soon!
          </Typography>
          <Typography className="font-bold text-3xl lg:text-6xl my-4 lg:my-4 w-full moveInDown delay1">
            Discover Style & Sustainability
          </Typography>
          <Typography className="font-normal mt-1 text-lg fadeIn delay500">
            Join Adore Again, where every piece tells a story and sustainability
            meets chic affordability.
          </Typography>

          <Typography className="text-3xl font-bold mt-20 fadeIn delay800">
            Sign Up to Stay Updated!
          </Typography>
          <Typography className="font-light text-normal fadeIn delay800 mt-1">
            We send emails as curated as our finds – no junk, only gems
          </Typography>
          <Link to={"signup"} smooth={true} duration={800}>
            <FaAnglesDown className="h-14 w-auto mx-auto mt-6 animate-bounce cursor-pointer" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
