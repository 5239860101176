import React from "react";
import hero from "./assets/hero/clothes.jpeg";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";

function App() {
  return (
    <div
      className="bg-fixed bg-center bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Home />
      <SignUp />
    </div>
  );
}

export default App;
